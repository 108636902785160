import React, { useEffect, useRef } from "react";
import { Autoplay, Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderArrow from "../components/SliderArrow";
import WpImage from "../components/WpImage";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/virtual";

const OurClientsSlider = ({ slides }) => {
  const swiperRef = useRef(null);
  const sliderRef = useRef(null);

  // Run autoplay if slider is in viewport using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          swiperRef.current?.autoplay.resume();
        } else {
          swiperRef.current?.autoplay.pause();
        }
      },
      { threshold: 0.5 }
    );

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
    };
  }, []);

  return (
    <div className="our-clients__slider">
      <Swiper
        ref={sliderRef}
        style={{
          padding: "0 32px 0 16px",
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Navigation, Autoplay, Virtual]}
        spaceBetween={50}
        slidesPerView={1}
        speed={1200}
        freeMode
        loop
        autoplay={{ disableOnInteraction: false, delay: 5000, pauseOnMouseEnter: true}}
        breakpoints={{
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
          1320: {
            slidesPerView: 2,
          },
          1820: {
            slidesPerView: 3,
          },
        }}
      >
        {slides.map((item, index) => (
          <SwiperSlide key={`${item.authorName}-${item.authorOffice}`}>
            <OurClientsItem key={`${item.authorName}-${item.authorOffice}`} {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SliderArrow onClick={()=>{swiperRef.current?.slideNext()}} className="our-clients__arrow -next" />
    </div>
  );
};

const OurClientsItem = ({ authorName, authorOffice, text, logo, offset }) => (
  <div className="our-clients__slide h-100" >
    <div className="our-clients__slide-content">
      {logo && (
        <div>
          <WpImage className={`our-clients__logo`} image={logo} />
        </div>
      )}
      <p className="our-clients__comment">{text}</p>
      <strong className="our-clients__author">
        {authorName}
        <span className="our-clients__author-sep">|</span>
        {authorOffice}
      </strong>
    </div>
  </div>
);

export default OurClientsSlider;
